import cloneDeep from 'clone-deep'
import { InputType, PaidByMap } from 'config'
import { useState } from 'react'
import { getPrice1or2decimal } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { Modal } from '../Modal/Modal'

interface MIPFundingFeeModalProps {
  data: Record<string, any>
  onClose: Function
}

const defaultInputs = (): Record<string, InputType | any> => {
  return {
    summary: {
      inputType: 'section',
      title: 'Summary',
      span: 2,
    },
    baseLoanAmount: {
      inputType: 'text',
      title: 'Base Loan Amount',
      disabled: true,
      span: 1,
      valueKey: 'Loan.BaseLoan',
    },
    loanWithMIP: {
      inputType: 'text',
      title: 'Loan With MIP/FF',
      disabled: true,
      span: 1,
      valueKey: 'Loan.BaseLoan',
    },
    financed: {
      inputType: 'text',
      title: 'Financed',
      disabled: true,
      span: 1,
      valueKey: 'Loan.MIPFFFinanced',
    },
    paidInCash: {
      inputType: 'text',
      title: 'Paid in Cash',
      disabled: true,
      span: 1,
      valueKey: 'Loan.MIPFFPaidInCash',
    },
    totalMIPFF: {
      inputType: 'text',
      title: 'Total MIP/FF',
      disabled: true,
      span: 1,
      valueKey: 'Loan.MIPFFPaidInCash',
    },
    detail: {
      inputType: 'section',
      title: 'Paid in Cash Detail',
      span: 2,
    },
    totalPaidInCash: {
      inputType: 'text',
      title: 'Total Paid in Cash',
      span: 1,
      valueKey: 'Loan.MIPFFPaidInCash',
    },
    paidBy: {
      inputType: 'select',
      title: 'Paid By',
      options: PaidByMap,
      span: 1,
      valueKey: 'Loan.MIPFFPaidInCashPaidByOtherType',
    },
    paidByOther: {
      inputType: 'text',
      title: 'Paid By Other',
      span: 1,
      valueKey: 'Loan.MIPFFPaidInCashPBSDesired',
    },
    paidByBorrower: {
      inputType: 'text',
      title: 'Paid By Borrower',
      span: 1,
      valueKey: 'Loan.MIPFFPaidInCashBorrower',
      disabled: true,
    },
    totalGoodFaithAmountHistory: {
      inputType: 'textarea',
      title: 'TRID Good Faith Amount History',
      span: 2,
      rows: 5,
      disabled: true,
    },
  }
}

export const MIPFundingFeeModal = ({ data, onClose }: MIPFundingFeeModalProps) => {
  const [modalData, setModalData] = useState<Record<string, any>>({
    'Loan.BaseLoan': data['Loan.BaseLoan'],
    'Loan.MIPFFFinanced': data['Loan.MIPFFFinanced'],
    'Loan.MIPFFPaidInCash': data['Loan.MIPFFPaidInCash'],
    'Loan.MIPFFPaidInCashPaidByOtherType': data['Loan.MIPFFPaidInCashPaidByOtherType'],
    'Loan.MIPFFPaidInCashPBSDesired': data['Loan.MIPFFPaidInCashPBSDesired'],
  })
  const inputs = defaultInputs()
  const onModalClose = () => {
    onClose(false)
  }

  const onModalSubmit = () => {
    onClose(true, modalData)
  }

  const onValueChanged = (key: string, value: any) => {
    const temp = cloneDeep(modalData)

    temp[key] = getPrice1or2decimal(value).replaceAll(',', '')
    setModalData(temp)
  }

  return (
    <Modal
      title={'Upfront MIP / Funding Fee'}
      titleOkay={'Okay'}
      isOpen
      onClose={onModalClose}
      onOk={onModalSubmit}
      titleCancel="Cancle"
      childLevel={1}
    >
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 text-sm">
        {Object.keys(inputs).map((key) => {
          const input = inputs[key]
          const { valueKey } = input
          input.value = modalData[valueKey]
          if (valueKey === 'Loan.MIPFFPaidInCashBorrower')
            input.value =
              (+modalData['Loan.MIPFFPaidInCash'] || 0) - (+modalData['Loan.MIPFFPaidInCashPBSDesired'] || 0)
          return (
            <div className={`col-span-${input.span || 1}`} key={key}>
              <RenderInput input={input} Key={valueKey} key={valueKey} onChange={onValueChanged} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
