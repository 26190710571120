import { DocumentDuplicateIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { InputType, PaidByMap } from 'config'
import { useState } from 'react'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { getDaysDiff, getPrice1or2decimal } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { Modal } from '../Modal/Modal'
import { OverrideCaclModal } from '../OverrideCalcModal/OverrideCalcModal'

interface MIPFundingFeeModalProps {
  data: Record<string, any>
  onClose: Function
}

const defaultInputs = (): Record<string, InputType | any> => {
  return {
    summary: {
      inputType: 'section',
      title: 'Interim Interest',
      span: 2,
    },
    from: {
      inputType: 'text',
      type: 'date',
      title: 'From',
      span: 1,
      valueKey: 'HUD1.InterimInterestDateFrom',
    },
    to: {
      inputType: 'text',
      type: 'date',
      title: 'To',
      span: 1,
      valueKey: 'HUD1.InterimInterestDateTo',
    },
    days: {
      inputType: 'text',
      type: 'number',
      title: 'Days',
      span: 1,
      valueKey: 'Loan.InterimInterestDays',
    },
    dailyInterest: {
      inputType: 'text',
      title: 'Daily Interest',
      span: 1,
      valueKey: 'Loan._DailyInterest',
      overrideKey: 'Loan.DailyInterestOV',
      disabled: true,
    },
    total: {
      inputType: 'text',
      title: 'Total',
      span: 1,
      valueKey: 'Loan._InterimInterest',
      overrideKey: 'Loan.InterimInterestOV',
      valueType: 'number',
      disabled: true,
    },
    paidBy: {
      inputType: 'select',
      title: 'Paid By',
      options: PaidByMap,
      span: 1,
      valueKey: 'Loan.InterimInterestPaidByOtherType',
    },
    paidByOther: {
      inputType: 'text',
      title: 'Paid By Other',
      span: 1,
      valueKey: 'Loan.InterimInterestPBSDesired',
      valueType: 'number',
    },
    paidByBorrower: {
      inputType: 'text',
      title: 'Paid By Borrower',
      span: 1,
      valueKey: 'Loan.InterimInterestPBA',
      valueType: 'number',
      disabled: true,
    },
    options: {
      inputType: 'section',
      title: 'Calculation Options',
      span: 2,
    },
    daysPerYear: {
      inputType: 'text',
      title: 'Days Per Year',
      span: 1,
      valueKey: 'Loan.InterimIntDaysPerYear',
      overrideKey: 'Loan.InterimIntDaysPerYearOV',
      ovOptions: {
        0: '',
        1: 360,
        2: 365,
      },
      disabled: true,
    },
    daysPerMonth: {
      inputType: 'text',
      title: 'Days Per Month',
      span: 1,
      valueKey: 'Loan.InterimIntDayCountCalcMethod',
      overrideKey: 'Loan.InterimIntDayCountCalcMethodOV',
      ovOptions: {
        0: '',
        1: 'Actual',
        2: 30,
      },
      disabled: true,
    },
    roundTo: {
      inputType: 'text',
      title: 'Round to',
      span: 1,
      valueKey: 'Loan.InterimIntDecimals',
      overrideKey: 'Loan.InterimIntDecimalsOV',
      ovOptions: {
        0: '',
        1: '2 Decimals',
        2: '3 Decimals',
        3: '4 Decimals',
      },
      disabled: true,
    },
    calculateUsing: {
      inputType: 'text',
      title: 'Calculate Using',
      span: 1,
      valueKey: 'Loan.BDInterimIntCalcMethod',
      overrideKey: 'Loan.BDInterimIntCalcMethodOV',
      ovOptions: {
        0: '',
        1: 'Note Rate',
        2: 'Buydown Start Rate',
        3: 'Fully Indexed Rate',
      },
      disabled: true,
    },
    additionalDays: {
      inputType: 'text',
      title: 'Additional Consented Days(CA Loans)',
      span: 1,
      valueKey: 'Status.InterimIntAdditionalConsentedDays',
      type: 'number',
    },
    totalGoodFaithAmountHistory: {
      inputType: 'textarea',
      title: 'TRID Good Faith Amount History',
      span: 2,
      rows: 5,
      disabled: true,
    },
  }
}

export const InterimInterestModal = ({ data, onClose }: MIPFundingFeeModalProps) => {
  const [modalData, setModalData] = useState<Record<string, any>>(data)
  const [ovData, setOVData] = useState<any>({})
  const inputs = defaultInputs()
  const onModalClose = () => {
    onClose(false)
  }

  const onModalSubmit = () => {
    onClose(true, modalData)
  }

  const onValueChanged = (key: string, value: any, type?: string) => {
    const temp = cloneDeep(modalData)

    if (type === 'number') temp[key] = getPrice1or2decimal(value).replaceAll(',', '')
    else temp[key] = value
    setModalData(temp)
  }

  const calculateValue = (key: string) => {
    let CALCULATED_VALUE: any = modalData[key]
    switch (key) {
      case 'Loan._DailyInterest':
        const roundTo = !modalData['Loan.InterimIntDecimalsOV'] ? 2 : Number(modalData['Loan.InterimIntDecimalsOV']) + 1
        CALCULATED_VALUE =
          Math.round(
            ((+modalData['HUD1.Line202Calc'] *
              (modalData['Loan.BDInterimIntCalcMethodOV'] == 3 && modalData['Loan.LoanProductType'] == 1
                ? +modalData['Loan.ARMIndexValue'] + +modalData['Loan.ARMMargin']
                : +modalData['Loan.interestRate'])) /
              100 /
              (modalData['Loan.InterimIntDaysPerYearOV'] == 2 ? 365 : 360)) *
              Math.pow(10, roundTo),
          ) / Math.pow(10, roundTo)
        break
      case 'Loan._InterimInterest':
        if (modalData['Loan.DailyInterestOV'])
          CALCULATED_VALUE = +modalData['Loan.InterimInterestDays'] * +modalData['Loan.DailyInterestOV']
        else {
          CALCULATED_VALUE = +modalData['Loan.InterimInterestDays'] * +calculateValue('Loan._DailyInterest')
        }
        CALCULATED_VALUE = Math.round(CALCULATED_VALUE * 100) / 100
        break
      case 'Loan.InterimInterestPBA':
        CALCULATED_VALUE =
          (+modalData['Loan.InterimInterestOV'] || calculateValue('Loan._InterimInterest')) -
          (+modalData['Loan.InterimInterestPBSDesired'] || 0)
        break
      case 'Loan.InterimIntDaysPerYear':
        CALCULATED_VALUE = 360
        break
      case 'Loan.InterimIntDayCountCalcMethod':
        CALCULATED_VALUE = 'Actual'
        break
      case 'Loan.InterimIntDecimals':
        CALCULATED_VALUE = '2 Decimals'
        break
      case 'Loan.BDInterimIntCalcMethod':
        CALCULATED_VALUE = 'Note Rate'
        break
      default:
        break
    }
    return CALCULATED_VALUE
  }

  const closeOVModal = (save: false, data: any) => {
    if (save) onValueChanged(ovData.overrideKey, data.ovValue)
    setOVData({})
  }

  const onCopyClick = (valueKey: string, set: boolean = true) => {
    let temp = cloneDeep(modalData)
    temp[valueKey] =
      modalData['Loan.InterimIntDayCountCalcMethodOV'] == 2
        ? calculateDayDifference30(modalData['HUD1.InterimInterestDateTo'], modalData['HUD1.InterimInterestDateFrom']) +
          1
        : getDaysDiff(modalData['HUD1.InterimInterestDateTo'], modalData['HUD1.InterimInterestDateFrom']) + 1
    if (set) setModalData(temp)
    else return temp[valueKey]
  }

  const calculateDayDifference30 = (date1: string, date2: string) => {
    const year1 = parseInt(date1.substring(0, 4))
    const month1 = parseInt(date1.substring(5, 7))
    const day1 = parseInt(date1.substring(8, 10))

    const year2 = parseInt(date2.substring(0, 4))
    const month2 = parseInt(date2.substring(5, 7))
    const day2 = parseInt(date2.substring(8, 10))

    // Calculate day difference assuming 30 days per month
    const yearDifference = (year1 - year2) * 360 // 360 days in a year
    const monthDifference = (month1 - month2) * 30 // 30 days per month
    const dayDifference = day1 - day2

    return yearDifference + monthDifference + dayDifference
  }

  return (
    <Modal
      title={'Interim Interest Rate'}
      titleOkay={'Okay'}
      isOpen
      onClose={onModalClose}
      onOk={onModalSubmit}
      titleCancel="Cancle"
      childLevel={1}
    >
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 text-sm">
        {Object.keys(inputs).map((key) => {
          const input = inputs[key]
          const { valueKey, overrideKey, valueType, title, ovOptions } = input
          input.value = calculateValue(valueKey)
          if (valueType === 'number') input.value = getPrice1or2decimal(input.value)
          if (overrideKey) {
            input.value = (ovOptions ? ovOptions[modalData[overrideKey]] : modalData[overrideKey]) || input.value
            input.additionalElements = (
              <span
                className="cursor-pointer hover:text-shade-blue"
                onClick={() =>
                  setOVData({
                    title: title,
                    overrideKey: overrideKey,
                    calcValue: calculateValue(valueKey),
                    ovValue: getPrice1or2decimal(modalData[overrideKey] || ''),
                    ovOptions,
                  })
                }
              >
                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
              </span>
            )
          }
          if (valueKey === 'Loan.InterimInterestDays') {
            if (onCopyClick('Loan.InterimInterestDays', false) != input.value)
              input.additionalElements = (
                <Tooltip message="Fill">
                  <span
                    className="cursor-pointer hover:text-shade-blue"
                    onClick={() => onCopyClick('Loan.InterimInterestDays')}
                  >
                    <DocumentDuplicateIcon className="w-4 h-4"></DocumentDuplicateIcon>
                  </span>
                </Tooltip>
              )
          }

          return (
            <div className={`col-span-${input.span || 1}`} key={key}>
              <RenderInput
                input={input}
                Key={valueKey}
                key={valueKey}
                onChange={(key: string, value: any) => onValueChanged(key, value, input.valueType)}
              />
            </div>
          )
        })}
        {Object.keys(ovData).length > 0 && (
          <OverrideCaclModal
            key={ovData.overrideKey}
            title={ovData.title}
            calcValue={ovData.calcValue}
            ovValue={ovData.ovValue}
            ovOptions={ovData.ovOptions}
            onClose={closeOVModal}
          />
        )}
      </div>
    </Modal>
  )
}
