export const baseUrl = process.env.REACT_APP_API_URL

export const API_USER_SIGN_IN = '/auth/login'

export const API_USER_ROLES = '/user/roles'
export const API_USER_PROPS = '/user/props/:userId'
export const API_USER_CREATE = '/user/submit'
export const API_USER_UPDATE = '/user/submit/:userId'
export const API_USER_UPDATE_DATA = '/user/update/:userId'
export const API_USER_PROFILE = '/user/profile'
export const API_USER_FILTER = '/user'
export const API_USER_BY_ID = '/user/:id'
export const API_USER_AUTO_COMPLETE = '/user/autoCompleteSearch'
export const API_USER_CHILDREN = '/user/children/:userId'
export const API_USER_UPDATE_STATUS = '/user/updateStatus/:userId'
export const API_USER_UPDATE_CHILD_STATUS = '/user/updateChildStatus/:userId'
export const API_USER_DELETE = '/user/delete/:userId'
export const API_USER_RECOVER = '/user/recover/:userId'
export const API_USER_UPDATE_PWD = '/user/updatePwd'
export const API_USER_PERMISSIONS = '/user/permissions'
export const API_USER_GET_LOGS = '/user/logs/:userId/:fieldName'
export const API_USER_EXPORT_HISTORY = '/user/exportHistory'

export const API_GET_ALL_PERMISSIONS = '/ac/details'
export const API_SET_PERMISSION_TO_ROLE = '/ac/role/permissions'
export const API_USER_TREE = '/user/userTree'
export const API_AUDIT_LOG = '/auditLog'
export const API_USER_CHILD_BROKER = '/user/childBroker/:userId'
export const API_USER_VALIDATE_BROKER_VERIFICATION_SIGNATURE = '/user/validateBrokerVerificationSignature'
export const API_USER_BROKER_VERIFICATION = '/user/brokerVerification'
export const API_USER_BROKER_PAYMENT = '/user/broker/makePayment/:userId'
export const API_USER_SIGN_BROKER_VERIFICATION_PDF = '/user/signBrokerVerificationPdf'
export const API_USER_BROKER_VERIFICATION_BY_USER = '/user/brokerVerification/:userId'
export const API_SEND_BROKER_VERIFICATION_MESSAGE = '/user/brokerVerificationMessage/:userId'
export const API_SEND_BROKER_VERIFICATION_PDF = '/user/sendBrokerVerificationPdf/:userId'
export const API_SEND_SIGN_BROKER_APPROVAL = '/user/sendSignBrokerApproval/:userId'
export const API_USER_DOWNLOAD_BROKER_AGREEMENT_PDF = '/user/downloadBrokerAgreementPdf/:userId'
export const API_USER_BY_COMPANY = '/user/companyName'

export const API_USER_ACTIVITY_FILTER = '/user/activity'
export const API_USER_ACTIVITIES = '/user/activity/:userId'
export const API_USER_ACTIVITIES_DOWNLOAD_CSV = `${API_USER_ACTIVITIES}/downloadCSV`
export const API_USER_ACTIVITIES_DOWNLOAD_PDF = `${API_USER_ACTIVITIES}/downloadPDF`

export const API_USER_ADMIN_EMAILS = '/user/getAdminEmails'
export const API_USER_FILTER_MASTER_EMAILS = '/user/filterMasterEmails'

export const API_HOME_NEWSLETTER = '/home/newsletter'
export const API_HOME_SUBMIT_CONTACTUS = '/home/submitContactUs'
export const API_HOME_CREDIT_BOX = '/home/homepageCreditBox'

export const API_BASE_VALIDATE_ADDRESS = '/home/validateAddress'

export const API_USER_NOTES = '/user/notes/:userId/:isBorrower'
export const API_USER_NOTES_BY_ID = `${API_USER_NOTES}/:id`

/**
 * Documents
 */
export const API_DOCS_DOWNLOAD = '/documents/download'
export const API_DOCS_MULTI_DOWNLOAD = '/documents/multiDownload'
export const API_DOCS_UPLOAD = '/documents/upload'

export const API_DOCUMENT_LIST_ALL = '/documents'
export const API_TEMPLATE_PROGRAMS = '/documents/templatePrograms'
export const API_DOCUMENT_LIST = '/documents/:type'
export const API_DOCUMENT_CREATE = '/documents'
export const API_DOCUMENT_UPDATE = '/documents/:id'
export const API_DOCUMENT_DELETE = '/documents/:id'
export const API_DOCUMENT_GET = '/documents/get/:id'
export const API_DOCUMENT_DOWNLOAD_BY_ID = '/documents/download/:id'

/**
 * Loan
 */
export const API_LOAN_OVERVIEW = '/loan/overview'
export const API_LOAN_GET_DUPLICATE_LOANS = '/loan/duplicateLoans/:loanNumber'
export const API_LOAN_UPDATE_FIELDS = '/loan/updateLoanFields'
export const API_LOAN_PIPELINE = '/loan/pipeline'
export const API_LOAN_MOVE_TO_SERVICING = '/loan/moveToServicing/:loanNumber'
export const API_LOAN_STATUS_CHANGE = '/loan/statusChange/:status/:loanNumber'
export const API_LOAN_SERVICING_STATUS_CHANGE = '/loan/servicingStatusChange/:status/:loanNumber'
export const API_LOAN_LOG = '/loan/log/:key'
export const API_LOAN_PROCESS_LOG = '/loan/process_log/:key/:loanNumber'
export const API_LOAN_HISTORY = '/loan/history'
export const API_LOAN_OVERVIEW_SECTION = '/loan/overviewSection'
export const API_LOAN_OVERVIEW_SECTION_BY_LOAN_NUMBER = '/loan/overviewSection/:loanNumber'
export const API_LOAN_UPDATE_CLOSING_DATE = '/loan/updateClosingDate'
export const API_LOAN_UPDATE_EMAIL_CONTACTS = '/loan/updateEmailContacts'
export const API_LOAN_CLONE_FILE = '/loan/cloneFile'
export const API_LOAN_SURVEY = '/loan/survey'
export const API_LOAN_GET_GEOLOCATION = '/loan/geoLocation'

/**
 * UnderWriting
 */
export const API_LOAN_GET_DATAVERIFY = '/loan/getLoanDataverify/:type'
export const API_LOAN_SUBMIT_DATAVERIFY_SSA_ORDER = '/loan/submitLoanDataverifySSAOrder'
export const API_LOAN_UPDATE_DATAVERIFY_SSA_STATUS = '/loan/updateLoanDataverifySSAStatus/:orderID'
export const API_LOAN_SUBMIT_DATAVERIFY_TAX_ORDER = '/loan/submitLoanDataverifyTaxOrder'
export const API_LOAN_UPDATE_DATAVERIFY_TAX_STATUS = '/loan/updateLoanDataverifyTaxStatus/:orderID'
export const API_LOAN_SUBMIT_DATAVERIFY_LEXIS_ORDER = '/loan/submitLoanLexisOrder'
export const API_LOAN_GET_ALL_DATAVERIFY = '/loan/getAllDataverify/:type'

export const API_DATAVERIFY_CREDENTIAL = '/loan/dataverifyCredential'
export const API_LOAN_DATAVERIFY_CONDITIONS = '/loan/dataverifyCondition'

export const API_LOAN_GET_NY_TRANSACTIONS = 'loan/nyTransactions'
export const API_LOAN_UPDATE_NY_TRANSACTIONS = 'loan/nyTransactions'
export const API_LOAN_GET_NY_TRANSACTIONS_LOG = 'loan/nyTransactions/log/:key'

export const API_LOAN_GET_UNDERWRITING_SUMMARY = 'loan/underwritingSummary'
export const API_LOAN_UPDATE_UNDERWRITING_SUMMARY = 'loan/underwritingSummary'
export const API_LOAN_GET_UNDERWRITING_SUMMARY_LOG = 'loan/underwritingSummary/log/:key'

export const API_LOAN_ESCROW_HOLDINGBACK = '/loan/underwriting/escrowHoldingback'

/**
 * Loan Application
 */
export const API_ADDITIONAL_PROPERTY_INFO = '/loan/additionalPropertyInfo'
export const API_ADDITIONAL_PROPERTY_INFO_LOGS = '/loan/additionalPropertyInfo/log/:fieldName'
export const API_UPDATE_ADDITIONAL_PROPERTY_INFO = '/loan/additionalPropertyInfo/:id'
export const API_DELETE_ADDITIONAL_PROPERTY_INFO = API_UPDATE_ADDITIONAL_PROPERTY_INFO

export const API_BORROWER_INFO = '/loan/borrowerInfo'
export const API_TEMPORARY_USER_INFO = '/loan/temporaryUserInfo'
export const API_SWAP_BORROWERS = '/loan/swapBorrowers'
export const API_LOAN_BORROWER_SIGN_STATUS = '/loan/getBorrowerSignAppStatus'
export const API_BORROWER_LOG = '/loan/borrowerLogs/:borrowerSeperator/:fieldName'
export const API_BORROWER_LOG_DOWNLOAD_CSV = `/loan/borrowerLogs/download/CSV`
export const API_BORROWER_LOG_DOWNLOAD_PDF = `/loan/borrowerLogs/download/PDF`
export const API_BORROWER_ACTIVITIES = '/borrowers/activity/:userId'

export const API_LOAN_APPLICATION_ASSET = 'loan/assetInformation'
export const API_LOAN_APPLICATION_ASSET_CREATE = API_LOAN_APPLICATION_ASSET
export const API_LOAN_APPLICATION_ASSET_RESET = `${API_LOAN_APPLICATION_ASSET}/reset`
export const API_LOAN_APPLICATION_ASSET_LOG = `${API_LOAN_APPLICATION_ASSET}/log`
export const API_LOAN_APPLICATION_ASSET_VERIFY = `${API_LOAN_APPLICATION_ASSET}/verify`
export const API_LOAN_APPLICATION_ASSET_UPDATE = `${API_LOAN_APPLICATION_ASSET}/:id`
export const API_LOAN_APPLICATION_ASSET_DELETE = API_LOAN_APPLICATION_ASSET_UPDATE
export const API_LOAN_APPLICATION_ASSET_VERIFY_BANK_STATEMENT = `${API_LOAN_APPLICATION_ASSET}/:id/verifyBankStatement`

export const API_LOAN_APPLICATION_MAIN_CREDIT_UPDATE = 'loan/mainCredits'
export const API_LOAN_APPLICATION_PURCHASE_CREDIT_UPDATE = 'loan/purchaseCredits'

export const API_LOAN_APPLICATION_LIABILITY = 'loan/liabilityInformation'
export const API_LOAN_APPLICATION_LIABILITY_CREATE = API_LOAN_APPLICATION_LIABILITY
export const API_LOAN_APPLICATION_LIABILITY_RESET = `${API_LOAN_APPLICATION_LIABILITY}/reset`
export const API_LOAN_APPLICATION_LIABILITY_UPDATE = `${API_LOAN_APPLICATION_LIABILITY}/:id`
export const API_LOAN_APPLICATION_LIABILITY_DELETE = API_LOAN_APPLICATION_LIABILITY_UPDATE

export const API_LOAN_APPLICATION_TRACK = 'loan/trackRecord'
export const API_LOAN_APPLICATION_TRACK_DOWNLOAD = 'loan/trackRecord/download'
export const API_LOAN_APPLICATION_TRACK_CREATE = API_LOAN_APPLICATION_TRACK
export const API_LOAN_APPLICATION_TRACK_UPDATE = `${API_LOAN_APPLICATION_TRACK}/:id`
export const API_LOAN_APPLICATION_TRACK_DELETE = API_LOAN_APPLICATION_TRACK_UPDATE

export const API_LOAN_APPLICATION_TRACK_DOCUMENT = 'loan/trackRecordDocument'
export const API_LOAN_APPLICATION_TRACK_DOCUMENT_DELETE = `${API_LOAN_APPLICATION_TRACK_DOCUMENT}/:id`

export const API_LOAN_APPLICATION_PULL_CREDIT_REPORT = 'loan/pullCreditReport'
export const API_LOAN_APPLICATION_PULL_CREDIT_REPORT_HISTORY = 'loan/pullCreditReportHistory/:borrowerSeperator'

export const API_LOAN_APPLICATION_SEND_APPLICATION_TO = 'loan/sendApplicationTo'
export const API_LOAN_APPLICATION_COPY_APPLICATION_TO_URL = 'loan/copyApplicationToUrl'
export const API_LOAN_APPLICATION_SEND_SIGNATURE_REQUEST = 'loan/sendSignatureRequest'
export const API_LOAN_APPLICATION_VALIDATE_SIGN_SIGNATURE = 'loan/validateSignSignature'
export const API_LOAN_APPLICATION_UPLOAD_SIGNED_PDF = 'loan/uploadSignedPdf'
export const API_LOAN_APPLICATION_ADD_HAND_SIGNED_PDF = 'loan/addHandSignedPdf'

export const API_LOAN_APPLICATION_ASSET_TRACK_LOG = '/loan/assetTrack/log/:key'

/**
 * Structure
 */
export const API_BUSINESS_HOUR = 'home/businessHour'
export const API_LOAN_LOCK_FLOAT_RATE = '/loan/loanLockOrFloatRate'
export const API_LOAN_SAVE_RATESHEET = '/loan/saveLoanRatesheet'
export const API_LOAN_GET_LATEST_RATESHEET = '/loan/getLatestLoanRatesheet'
export const API_LOAN_GET_PROCESS = '/loan/getLoanProcessRate'
export const API_LOAN_LOCK_EXTENSION = '/loan/lockExtension'
export const API_LOAN_NOTIFY_PDF_TO_CREATOR = '/loan/notifyPdfToCreator'
export const API_LOAN_OVERRIDE_PRICING = '/loan/overridePricing'
export const API_LOAN_REPRICE_AFTER_LOCKED = '/loan/rePriceAfterLocked'
export const API_LOAN_RE_PRICING_INIT = '/loan/rePricingInitData'
export const API_LOAN_SAVE_PROCESS = '/loan/saveLoanProcess'
export const API_LOAN_SAVE_TO_PIPELINE = '/loan/saveToPipeline'
export const API_LOAN_STRUCTURE_HISTORY = '/loan/loanStructureHistory/:index'
export const API_LOAN_STRUCTURE_RELOCK_TIMES = '/loan/getRelockTimes'
export const API_LOAN_SUBMIT_EXCEPTION_REQUEST = '/loan/submitExceptionRequest'
export const API_LOAN_SUBMIT_EXCEPTION_RESPONSE = '/loan/submitExceptionResponse'
export const API_LOAN_CREATE_ADDRESS_DOCUMENT = '/loan/createAddressDocument'

/**
 * Loan Submission
 */
export const API_LOAN_GET_SUBMISSION = '/loan/submission'
export const API_LOAN_GET_SUBMISSION_CONDITIONS_TEMPORARY = '/loan/submission/conditionsForTemporaryUser'
export const API_LOAN_SUBMISSION_LOG = '/loan/submission/log/:key'
export const API_LOAN_SUBMISSION_NOTIFY = '/loan/submission/notify'
export const API_LOAN_UPDATE_SUBMISSION = '/loan/updateSubmission'
export const API_LOAN_BORROWER_UPLOADED_DOCUMENTS = '/loan/borrowerUploadedDocuments'
export const API_LOAN_SUBMISSION_DOCUMENTS = '/loan/getLoanSubmissionDocuments'
export const API_LOAN_SUBMISSION_REVIEW_REQUEST = '/loan/reviewRequest/:key'
export const API_LOAN_SUBMISSION_DOCUMENT_HISTORY = '/loan/submissionDocumentHistory'
export const API_LOAN_SUBMISSION_CLEARED_UNDERWRITER_TASK = '/loan/conditionClearedUnderwriterTask/:conditionNo'
export const API_LOAN_SEND_SUBMISSION_PDF_EMAIL = '/loan/sendSubmissionPdfEmail'
export const API_LOAN_PROC_RUSH_PAYMENT = 'loan/process/rushPayment'

export const API_LOAN_SUBMIT_TO_LOANSETUP = '/loan/submitToLoanSetup'
export const API_LOAN_SUBMIT_CLOSED_LOAN_REVIEW = '/loan/submitClosedLoanReview'
export const API_LOAN_SUBMIT_CLOSING_CONDITION_CLEARED = '/loan/submitClosingConditionCleared'
export const API_LOAN_SUBMIT_CONDITIONS = '/loan/submitConditions'
export const API_LOAN_SUBMIT_TO_UNDERWRITER = '/loan/submitToUnderwriter'
export const API_LOAN_SUBMIT_TO_AE = '/loan/submitToAE'
export const API_LOAN_SUBMIT_CTC_REVIEW = '/loan/submitCTCReview'
export const API_LOAN_SEND_LOAN_SUBMISSION_DOCUMENT_EMAIL = 'loan/sendLoanSubmissionDocumentEmail'

/**
 * BytePro
 */
export const API_LOAN_BYTEPRO_LOGS = '/loan/byteproLogs'
export const API_LOAN_BYTEPRO_LOG_DETAIL = '/loan/byteproLog/:id'
export const API_LOAN_GET_BYTEPRO_FILENAME = '/loan/getByteproFileName/:loanNumber'
export const API_LOAN_CONNECT_BYTEPRO = '/loan/connectToBytepro'

/**
 * Generate Documents
 */
export const API_LOAN_GENERATE_DOCUMENTS = '/loan/generateDocuments'
export const API_LOAN_DELETE_DOCUMENT_HISTORY = '/loan/deleteDocumentHistory'
export const API_LOAN_GENERATE_DOCUMENTS_URL = '/loan/getGenerateDocumentUrl/:id'
export const API_LOAN_GENERATE_DOCUMENTS_LOG = '/loan/generateDocuments/log/:key'

/**
 * Document Genie
 */
export const API_LOAN_GET_DOCUMENT_GENIE = '/loan/documentGenie'
export const API_LOAN_SEND_DOCUMENT_GENIE_SIGN_REQUEST = '/loan/documentGenie'
export const API_LOAN_UPLOAD_SIGNED_DOCUMENT_GENIE_PDF = 'loan/uploadSignedDocumentGeniePdf'
export const API_LOAN_GET_OVERVIEW_FOR_DOCUMENT_GENIE = 'loan/overviewForDocumentGenie'

/**
 * Closing Calendar
 */
export const API_CLOSING_CALENDAR = 'loan/closingCalendar'
export const API_CLOSING_CALENDAR_DETAILS = 'loan/closingCalendar/getDetails'
export const API_CLOSING_CALENDAR_SIGN_EMAIL = 'loan/closingCalendar/sendSignEmail'

/**
 * Closing Screen
 */
export const API_LOAN_CLOSING_SETTLEMENT = '/loan/loanClosingSettlement'

/**
 * Party
 */
export const API_LOAN_PARTY_OPTIONS = '/loan/partyOptions/:key'
export const API_LOAN_UPDATE_PARTY = '/loan/updateParty'
export const API_LOAN_PARTY_ADDITIONAL_INFO = 'loan/partyAdditionalInfo/:type'
export const API_LOAN_GET_PARTIES = 'loan/parties/:loanNumber'
export const API_LOAN_UPDATE_PARTIES = 'loan/parties/:loanNumber/:PartyID'

/**
 * Post Closing
 */
export const API_POST_CLOSING_FIELDS = 'loan/postClosingFields/:menu'
export const API_POST_CLOSING_DOCS = 'loan/postClosingDocs'

export const API_PURCHASE_ADVICE_DATA = '/loan/purchaseAdvice'
export const API_PURCHASE_ADVICE_LOG = '/loan/purchaseAdvice/log/:key'
export const API_PURCHASE_ADVICE_SELLER_DATA = '/loan/purchaseAdviceSeller'
export const API_PURCHASE_ADVICE_SELLER_SIGN = '/loan/purchaseAdviceSeller/sign'
export const API_PURCHASE_ADVICE_SELLER_SIGN_BY_ID = '/loan/purchaseAdviceSeller/sign/:id'
export const API_PURCHASE_ADVICE_SELLER_LOG = '/loan/purchaseAdviceSeller/log/:key'

export const API_LOAN_GET_SHIPPING_INFO = '/loan/shippingInfo'
export const API_LOAN_UPDATE_SHIPPING_INFO = '/loan/shippingInfo'
export const API_LOAN_GET_SHIPPING_INFO_LOG = '/loan/shippingInfo/log/:key'
//---> Post Closing

export const API_LOAN_DELETE = '/loan/:loanNumber'
export const API_LOAN_DELETE_ALL = '/loan/:loanNumber/all'

export const API_LOAN_STATISTICS = 'loan/statistics'
export const API_PIPELINE_BUSINESS_DAYS = 'loan/businessDays'

export const API_VENDOR_CREDIT_REPORT = '/vendor'
export const API_VENDOR_OPTIONS_BY_TYPE = '/vendor/:type'
export const API_VENDOR_OPTIONS_BY_TYPE_PUBLIC = '/vendor/:type/public'
export const API_VENDOR_OPTIONS_UPATE_BY_TYPE_AND_INDEX = '/vendor/:type'
export const API_VENDOR_OPTIONS_DELECT_BY_TYPE_AND_KEY = '/vendor/:type'
export const API_VENDOR_OPTIONS_Active_BY_TYPE_AND_KEY = '/vendor/:type'
export const API_VENDOR_ENABLED_BY_TYPE = '/vendor/:type/enabled'
export const API_VENDOR_ENABLED_BY_TYPES = '/vendor/enabled'

export const API_CONDITIONS = '/condition'
export const API_CONDITION_BROKER_TEMPLATES = '/condition/brokerTemplates'
export const API_CONDITION_BROKER_TEMPLATE_BY_ID = '/condition/brokerTemplate/:id'
export const API_CONDITIONS_SIMPLY = '/condition/simply'
export const API_CONDITION_CREATE = '/condition'
export const API_CONDITION_UPDATE = '/condition/:id'
export const API_CONDITION_KEY_UPDATE = '/condition/:id/:key'
export const API_CONDITION_DELETE = '/condition/:id'
export const API_CONDITION_NEW_NUMBER = '/condition/newNo'
export const API_CONDITIONS_REPLACE_CONTENT = '/condition/replaceContent'

export const API_GET_ROLODEX = '/rolodex'
export const API_SUBMIT_ROLODEX = '/rolodex'
export const API_UPDATE_ROLODEX = '/rolodex/:id'
export const API_DELETE_ROLODEX = '/rolodex/:id'
export const API_GET_ALL_ROLODEX = 'rolodex/all'
export const API_GET_ROLODEX_CATEGORIES = '/rolodex/categories'

export const API_EMAIL_TEMPLATE = '/emailTemplate'
export const API_EMAIL_TEMPLATE_BY_ID = '/emailTemplate/:id'

export const API_SERVICING_NOTE_TEMPLATE = '/servicingNoteTemplate'
export const API_SERVICING_NOTE_TEMPLATE_BY_ID = '/servicingNoteTemplate/:id'
export const API_SERVICING_NOTE_TEMPLATE_ORDER_CHANGE = '/servicingNoteTemplateOrderChange'

export const API_PAYMENT_TYPE_TEMPLATE = '/paymentTypeTemplate/:orderType'
export const API_PAYMENT_TYPE_TEMPLATE_BY_ID = `${API_PAYMENT_TYPE_TEMPLATE}/:id`

export const API_INVESTOR_SERVICER = '/investorServicer'
export const API_INVESTOR_SERVICER_ITEM = '/investorServicer/:id'

export const API_CONDITIONS_BY_TEMPLATE = '/conditions/:templateNo'
export const API_CONDITIONS_BY_NOS = '/conditions/byConditionNo'

export const API_TEMPLATES = '/template'
export const API_TEMPLATE_CREATE = '/template'
export const API_TEMPLATE_UPDATE = '/template/:id'
export const API_TEMPLATE_DELETE = '/template/:id'

export const API_TASK_TEMPLATE = '/taskTemplate'
export const API_TASK_TEMPLATE_CREATE = '/taskTemplate'
export const API_TASK_TEMPLATE_UPDATE = '/taskTemplate/:id'
export const API_TASK_TEMPLATE_DELETE = '/taskTemplate/:id'

export const API_TASK_PROJECT = '/task/project'
export const API_TASK_NEW_TASK = '/task'
export const API_TASK_GET_TASK_BY_PROJECTID = '/task/:projectId'
export const API_TASK_UPDATE_TASK = '/task/:taskId'
export const API_TASK_UPDATE_TASK_STATUS = '/task/status/:taskId'
export const API_TASK_GET_TASK_HISTORY = '/task/history/:taskId'
export const API_TASK_BY_LOAN_NUMBER = '/task/loan/:loanNumber'
export const API_TASK_GET_TASK_BY_TASKNO = '/task/item/:taskNo'
export const API_TASK_ADD_FROM_TEMPLATES = '/task/fromTemplate/:projectId/:loanNumber'
export const API_TASK_BY_USER_ID = '/task/user/:userId'

export const API_CONVERT_TABLE = '/admin/convertTable'
export const API_CONVERT_LOAN = '/admin/convertLoan/:loanNumber'
export const API_ADMIN_GET_SETTING = '/api/setting/:key'
export const API_LOAN_SHARE_SCENARIO = '/api/shareScenario'
export const API_LOAN_SHARE_SCENARIO_GET = '/api/shareScenario/:token/:id'
export const API_LOAN_SCENARIO = '/loan/scenario'

export const API_LOAN_TERM_SHEET = '/api/loanTermSheet/:productType/:transactionType'
export const API_ADMIN_UPDATE_SETTING = '/ac/setting'
export const API_PERMISSION_DETAILS = '/ac/permissionDetails'
export const API_GET_NAVBAR_DATA = '/ac/getNavBarData'

export const API_SEND_BROKER_VERIFICATION_REQUEST = '/user/sendBrokerVerificationRequest/:userId'
export const API_UPDATE_BROKER_VERIFICATION_DOCUMENT = '/user/updateBrokerVerificationRequest/:userId'

/**
 * Order Appraisal
 */
export const API_LOAN_CANCEL_APPRAISAL_INFO_V2 = '/loan/appraisalInformationCancelOrderV2'
export const API_LOAN_DELETE_APPRAISAL_INFO_V2 = '/loan/appraisalInformationDeleteV2/:id'
export const API_LOAN_UPDATE_APPRAISAL_INFO_V2 = '/loan/appraisalInformationUpdateV2/:id'
export const API_LOAN_CREATE_APPRAISAL_INFO_V2 = '/loan/appraisalInformationCreateV2'
export const API_LOAN_CREATE_APPRAISAL_V2_CREATE_LOG = '/loan/appraisalInformationCrateLogV2'
export const API_LOAN_APPRAISAL_INFORMATION_FEED_V2 = '/loan/appraisalInformationFeedV2'
export const API_LOAN_ORDER_VALUATION_FEASIBILITY_SIGN_REQUEST = '/loan/orderValuationFeasibilitySignRequest/:id'
export const API_LOAN_ORDER_VALUATION = 'loan/orderValuation/:id'
export const API_LOAN_APPRAISAL2_STATUS_CHANGE = '/loan/appraisalInformationStatusChange'
export const API_LOAN_APPRAISAL2_LOG = '/loan/appraisalInformationLog/:key/:id'
export const API_LOAN_POST_APPRAISAL2_DOCUMENTS = '/loan/appraisal2Documents'
export const API_LOAN_POST_APPRAISAL2_PAY_OR_INVOICE = '/loan/appraisal2PayOrInvoice'

export const API_APPRAISAL_INFORMATION = '/loan/appraisalInformation'
export const API_APPRAISAL_INFORMATION_UPDATE = '/loan/appraisalInformation/:id'
export const API_ALL_ORDER_APPRAISAL2 = '/loan/findAllOrderAppraisal2'
export const API_STATISTICS_APPRAISAL2 = '/loan/appraisal2Statistics'

export const API_LOAN_APPRAISAL_ORDER_DETAILS = '/loan/appraisalOrderDetails'
// export const API_LOAN_POST_APPRAISAL_ORDER = '/loan/postAppraisalOrder'
export const API_LOAN_SYNC_ORDER_APPRAISAL_INFO = '/loan/syncOrderAppraisalInfo'
export const API_LOAN_GET_APPRAISAL_COMMENTS = '/loan/getAppraisalComments/:appraisalID'
export const API_LOAN_POST_APPRAISAL_COMMENTS = '/loan/postAppraisalComments'
// export const API_LOAN_APPRAISAL_PAY_INVOICE = '/loan/appraisalPayInvioce'

// Trinity
export const API_LOAN_TRINITY_PROGRESS_STATUS = '/loan/getTrinityProgressStatus/:OrderId/:TrinityOrderId'
export const API_LOAN_POST_TRINITY_REPORT_TO_DOCSTORAGE = '/loan/sendTrinityToDocStorage/:OrderId'
export const API_LOAN_GET_TRINITY_COMMENTS_LIENS = '/loan/getTrinityCommentsLiens/:OrderId/:TrinityOrderId'
export const API_LOAN_POST_TRINITY_COMMENTS = '/loan/postTrinityComments'
export const API_LOAN_POST_TRINITY_DOCUMENTS = '/loan/postTrinityDocuments'

// Elite
export const API_LOAN_ELITE_PROGRESS_STATUS = '/loan/getEliteProgressStatus/:OrderId'
export const API_LOAN_GET_ELITE_VALUATION_COMMENTS = '/loan/getEliteValuationComments/:OrderId'
export const API_LOAN_POST_ELITE_VALUATION_COMMENTS = '/loan/postEliteValuationComments'
export const API_LOAN_POST_ELITE_VALUATION_DOCUMENTS = '/loan/postEliteValuationDocuments'

export const API_LOAN_USER_CHECK_ELITE_VALUATION = '/loan/checkUserEliteValuation'
export const API_LOAN_USER_GET_ELITE_VALUATION_CREDENTIAL = '/loan/getEliteValuationCredential'
export const API_LOAN_GET_ELITE_VALUATION_DETAILS = '/loan/getEliteValuationDetail'
export const API_LOAN_POST_ELITE_VALUATION = '/loan/postEliteValuation'
export const API_LOAN_GET_ELITE_VALUATION_ORDER_PROGRESS = '/loan/getEliteValuationOrderProgress/:orderID'
export const API_LOAN_GET_BORROWER_ELITE_VALUATION_KEY = 'loan/getBorrowerEliteCredential'

//Appraisal Nation
export const API_LOAN_USER_GET_APPRAISAL_NATION_CREDENTIAL = '/loan/getAppraisalNationCredential'
export const API_LOAN_USER_CHECK_APPRAISAL_NATION = '/loan/checkUserAppraisalNation'

// Nation Wide
export const API_LOAN_USER_GET_NATION_WIDE_CREDENTIAL = '/loan/getNationWideCredential'
export const API_LOAN_USER_CHECK_NATIONWIDE = '/loan/checkUserNationWide'
export const API_LOAN_GET_NATIONWIDE_COMMENTS = '/loan/getNationWideComments/:appraisalID'
export const API_LOAN_POST_NATIONWIDE_COMMENTS = '/loan/postNationWideComments'

/**
 * Second Marketing
 */
export const API_SECONDARY_MARKETING_SCREEN_DATA = '/loan/secondaryMarketingScreen'

/**
 * Finresi
 */
export const API_LOAN_PRICING = '/loan/priceLoan'
export const API_CURRENT_PRODUCT_PROGRAM = '/loan/currentProductTypesPrograms'

export const API_EXPIRATION_DATES = '/loan/expirationDates'
export const API_EXPIRATION_DATES_UPDATE = '/loan/expirationDates/:type'

export const API_FLOOR_ORDER = '/loan/floodOrder'
export const API_SYNC_NATIONAL_FLOOR = '/loan/syncNationalFlood'
export const API_FIND_NATIONAL_FLOOR = '/loan/findNationalFlood'

export const API_RATESHEET = '/ratesheet/'
export const API_RATESHEET_UPDATE = '/ratesheet/:id'

export const API_RESOURCE_PROGRAM_RESTRICTION = '/resources/programRestriction'

export const API_GUIDELINE_CONDITION = '/resources/guidelineCondition/:id'

export const API_RESOURCE_COMPANY_APPRAISAL = '/resources/CompanyAppraisal'
export const API_RESOURCE_COMPANY_APPRAISAL_BY_ID = `${API_RESOURCE_COMPANY_APPRAISAL}/:id`

export const API_REVIEW_FORM = '/reviewForm'
export const API_REVIEW_FORM_BY_ID = '/reviewForm/:id'
export const API_REVIEW_FORM_TEMPLATES = '/reviewForm/template'
export const API_REVIEW_FORM_TEMPLATE_BY_ID = '/reviewForm/template/:id'
export const API_REVIEW_FORM_TEMPLATE_ORDER_BY_ID = '/reviewForm/templateOrder/:id'

export const API_ADMIN_GET_ARM_DETAILS = '/armDetails'
export const API_ADMIN_CREATE_ARM_DETAIL = '/armDetails'
export const API_ADMIN_UPDATE_ARM_DETAIL = '/armDetails/:id'
export const API_ADMIN_DELETE_ARM_DETAIL = '/armDetails/:id'

export const API_LOAN_APPLICATION_ADDITIONAL_MEMBER = 'loan/additionalMember'
export const API_LOAN_APPLICATION_ADDITIONAL_MEMBER_ITEM = `${API_LOAN_APPLICATION_ADDITIONAL_MEMBER}/:id`
export const API_LOAN_APPLICATION_ADDITIONAL_MEMBER_PULL_CREDIT_REPORT = `${API_LOAN_APPLICATION_ADDITIONAL_MEMBER}/pullCreditReport`
export const API_LOAN_APPLICATION_ADDITIONAL_MEMBER_SEND_SIGNATURE_REQUEST = `${API_LOAN_APPLICATION_ADDITIONAL_MEMBER}/sendSignatureRequest`
export const API_LOAN_APPLICATION_ADDITIONAL_MEMBER_VALIDATE_SIGN_SIGNATURE = `${API_LOAN_APPLICATION_ADDITIONAL_MEMBER}/validateSignSignature`
export const API_LOAN_APPLICATION_ADDITIONAL_MEMBER_UPLOAD_SIGNED_PDF = `${API_LOAN_APPLICATION_ADDITIONAL_MEMBER}/uploadSignedPdf`

export const API_LOAN_PUSH_TO_YIELDEASY = 'loan/pushToYieldeasy'

export const API_LOAN_VALIDATE_LOAN_NUMBERS = 'loan/validateLoanNumbers'
export const API_LOAN_GET_ZILLOW_URL = 'loan/getZillowUrl/:zipCode'
export const API_LOAN_SITEXPRO_REPORT = 'loan/sitexProReport'

export const API_EMAIL_BROADCAST = '/admin/broadcastEmail'

export const API_BANKER_DATA = '/admin/getBankerData'
export const API_BANKER_UPDATE_DATA = '/admin/updateBankerData'
export const API_BANKER_DELETE_DATA = '/admin/deleteBankerData'
export const API_LOGIN_PAGE_MODAL = '/admin/logInPageModal'

export const API_ACCOUNT_EXECUTIVES = '/admin/accountExecutives'
export const API_ACCOUNT_EXECUTIVES_BY_ID = '/admin/accountExecutives/:id'

export const API_FAQ = '/admin/landingLearnMore'
export const API_FAQ_BY_ID = '/admin/landingLearnMore/:id'
export const API_FAQ_EXCHANGE_ORDER = '/admin/landingLearnMore/order/:id1/:id2'
export const API_UPDATE_LOAN_TERM_SHEET = '/admin/loanTermSheet/:id'
export const API_SYNC_LOAN_TERM_SHEET = '/admin/loanTermSheet/sync/:id'
export const API_REMOVE_LOAN_TERM_SHEET = '/admin/loanTermSheet/:id/:index'

/**
 * Loan Budget
 */
export const API_BUDGET_TEMPLATE = 'loan/budget'
export const API_BUDGET_TEMPLATE_BY_ID = 'loan/budget/:id'
export const API_BUDGET_TEMPLATE_ITEM = 'loan/budget/updateData/:id'
export const API_BUDGET_TEMPLATE_BORROWER_CONFIRM = 'loan/budget/borrowerConfirmSubmit/:id'
export const API_BUDGET_TEMPLATE_LOCK_BORROWER = 'loan/budget/lockBorrower/:id'
export const API_BUDGET_TEMPLATE_SEND_TO_BORROWER = 'loan/budget/sendToBorrower/:id'
export const API_BUDGET_TEMPLATE_SIGN_SIGNATURE = 'loan/budget/validateSignSignature/:id'
export const API_BUDGET_TEMPLATE_PARSE_EXCEL = 'loan/budget/parseExcel'
export const API_BUDGET_TEMPLATE_EXPORT_QA = 'loan/budget/exportQA'

/**
 * Servicing
 */
export const API_LOAN_UPDATE_SERVICE_EXTENSION = '/loan/updateServiceExtension/:id'
export const API_LOAN_CREATE_SERVICE_EXTENSION = 'loan/createServiceExtension'
export const API_LOAN_GET_SERVICE_EXTENSION = 'loan/getServiceExtension'
export const API_LOAN_GET_ONE_SERVICE_EXTENSION = 'loan/getOneServiceExtension/:id'

export const API_LOAN_SERVICING_PARTICIPATION = '/loan/servicingParticipation'

export const API_LOAN_DOWNLOAD_SERVICING_DOC2 = '/loan/downloadServicingDocument2'
export const API_LOAN_SEND_SERVICING_DOC_EMAIL = '/loan/sendServicingDocumentEmail'

export const API_LOAN_GET_SERVICING_DOCUMENTS_DATA = '/loan/servicingDocumentData'

export const API_LOAN_GET_SERVICING_NOTES = '/loan/servicingNotes'

export const API_LOAN_GET_SERVICING_DEFAULT_TRACKING = '/loan/servicingDefaultTracking'
export const API_LOAN_GET_SERVICING_DEFAULT_TRACKING_LOG = '/loan/servicingDefaultTrackingLog/:key'

// Trust Ledger
export const API_TRUST_LEDGER_VALIDATE_SIGN_SIGNATURE = 'loan/trustLedgerSignUrlValitate'
export const API_TRUST_LEDGER_STATUS_CHANGE_DRAW_AGREEMENT = 'loan/trustLedgerAgreementStatusChange'
export const API_TRUST_LEDGER = 'loan/trustLedger'
export const API_TRUST_LEDGER_ADDITIONAL = 'loan/trustLedgerAdditional'
export const API_TRUST_LEDGER_ADDITIONAL_INFO = 'loan/trustLedgerAdditionalInfo'
export const API_TRUST_LEDGER_NOTIFICATION = 'loan/trustLedgerNotification'

export const API_CONSTRUCTION_LEDGER = 'loan/constructionLedger'

export const API_LOAN_TRUST_LEDGER_ORDER_HISTORY = 'loan/trustLedgerOrderHistory/:id'
export const API_LOAN_TRUST_LEDGER_DRAW_ISSUED_TASK = 'loan/trustLedgerDrawIssuedTask'
export const API_LOAN_TRUST_LEDGER_INTEREST = 'loan/trustLedgerInterest'
export const API_LOAN_TRUST_LEDGER_INTEREST_CREATE = API_LOAN_TRUST_LEDGER_INTEREST
export const API_LOAN_TRUST_LEDGER_INTEREST_RESET = `${API_LOAN_TRUST_LEDGER_INTEREST}/reset`
export const API_LOAN_TRUST_LEDGER_INTEREST_UPDATE = `${API_LOAN_TRUST_LEDGER_INTEREST}/:id`
export const API_LOAN_TRUST_LEDGER_INTEREST_DELETE = API_LOAN_TRUST_LEDGER_INTEREST_UPDATE

// Note
export const API_NOTE = '/loan/note/:loanNumber'
export const API_NOTE_BY_ID = '/loan/note/:id'

// Invoice
export const API_LOAN_INVOICE = '/loan/invoice'
export const API_LOAN_INVOICE_UPDATE = '/loan/invoice/:id'

/**
 * Loan Funding Disbursement
 */
export const API_LOAN_AMORTIZATION_SCHEDULE = '/loan/amortizationSchedule'
export const API_LOAN_FUNDING_DISBURSEMENT = '/loan/fundingDisbursement'
export const API_LOAN_FUNDING_DISBURSEMENT_LOG = '/loan/fundingDisbursementLog/:key'

/**
 * Loan Contractor
 */
export const API_LOAN_CONTRACTOR = '/loan/contractor'
export const API_LOAN_CONTRACTOR_BY_NO = '/loan/contractor/:no'
export const API_LOAN_CONTRACTOR_DOCUMENTS = '/loan/contractor/:no/documents'
export const API_LOAN_CONTRACTOR_LOG = '/loan/contractor/:no/log/:key'
export const API_LOAN_CONTRACTOR_SEND_APPLICATION = '/loan/contractor/:no/sendApplication'
export const API_LOAN_CONTRACTOR_VALIDATE_SIGN = '/loan/contractor/:no/signValidate'
export const API_LOAN_CONTRACTOR_SIGN = '/loan/contractor/:no/signApplication'
export const API_LOAN_CONTRACTOR_APP_PDF = '/loan/contractor/:no/getPDF'
export const API_LOAN_CONTRACTOR_UPLOAD_DOCUMENTS = '/loan/contractor/:no/uploadDocuments'
export const API_LOAN_CONTRACTOR_MESSAGE = '/loan/contractor/:no/message'

export const API_PDF_SSA = '/pdf/exportSSAPdf/:borrowerSeperator'
export const API_PDF_LOAN_SIGN = '/pdf/exportLoanSignAppPdf'
export const API_PDF_LOAN_STRUCTURE = '/pdf/loanStructurePdf'
export const API_PDF_DOCUMENT_GENIE = '/pdf/exportDocumentGenie'
export const API_PDF_APPRAISAL_REVIEW = '/pdf/appraisalReviewPdf'
export const API_PDF_PURCHASE_ADVICE = '/pdf/purchaseAdvicePdf'
export const API_PDF_LOAN_TERM_SHEET = '/pdf/loanTermSheetPdf'
export const API_PDF_CREDIT_AUTHORIZATION = '/pdf/exportCreditAuthorizationFormPdf'
export const API_PDF_LOAN_SUBMISSION = '/pdf/exportLoanSubmissionPdf'
export const API_PDF_POST_CLOSING = 'loan/exportPostClosingPdf/:menu'
export const API_PDF_CLOSING_CALENDAR = '/pdf/closingCalendarPdf'

export const API_STRIPE_CLIENT_SESSION_KEY = '/payment/stripeClientKey'
export const API_STRIPE_INTENT = '/payment/stripeIntent'
export const API_STRIPE_INTENT_BY_ID = '/payment/stripeIntent/:id'
export const API_PAYMENT_DATA = '/payment/paymentData'
export const API_PAYMENT_FIND_DATA = '/payment/findStripeIntent'

/**
 * Payments
 */
export const API_PAY_PLAID_CLIENT_TOKEN = '/payment/plaid/clientToken/:type'
export const API_PAY_PLAID_ACCOUNTS_BALANCE = '/payment/plaid/balance'
export const API_PAY_SEND_PAYMENT_LINK = '/payment/stripe/sendPaymentLink'
export const API_PAY_INVOICE = '/payment/invoice'
export const API_REFUND_INVOICE = '/payment/refundInvoice/:intentId'

/**
 * HUD Screen
 */
export const API_LOAN_GET_HUD1_PAGE1 = '/loan/hud1/:page'
export const API_LOAN_GET_LOG_HUD1_PAGE1 = '/loan/hud1/log/:key'
export const API_LOAN_GET_HUD1_NOTES = '/loan/hud1/notes/:key'
export const API_LOAN_DOWNLOAD_HUD1_PDF = '/loan/hud1/downloadPDF/:page'
export const API_LOAN_DOWNLOAD_NET_FUNDING_SHEET = '/loan/hud1/downloadNetFundingSheet'
export const API_LOAN_DOWNLOAD_FEE_WORKSHEET = '/loan/hud1/downloadFeeWorksheet'
export const API_LOAN_DOWNLOAD_DISBURSEMENT_ITEMS = '/loan/hud1/disbursementItems'
export const API_LOAN_PROGRAM_HUD1 = '/loan/hud1/loanProgram'

/**
 * Admin
 */
export const API_ADMIN_ACCOUNTING_CATEGORY = '/admin/accounting/category'
export const API_ADMIN_ACCOUNTING_CATEGORY_BY_ID = '/admin/accounting/category/:id'

export const API_ADMIN_ACCOUNTING = 'admin/accounting/data'
export const API_ADMIN_ACCOUNTING_BY_ID = 'admin/accounting/data/:id'
export const API_ADMIN_ACCOUNTING_HISTORY_BY_ID = 'admin/accounting/data/:id/history'
export const API_ADMIN_ACCOUNTING_RELATIONS_BY_ID = 'admin/accounting/data/:id/relations'
export const API_ADMIN_ACCOUNTING_BY_FILE_ID = 'admin/accounting/data/:fileId/file'

export const API_ADMIN_ACCOUNTING_ACCOUNT = 'admin/accounting/account'
export const API_ADMIN_ACCOUNTING_ACCOUNT_BY_ID = 'admin/accounting/account/:id'

export const API_ADMIN_ACCOUNTING_FILE = 'admin/accounting/file'
export const API_ADMIN_ACCOUNTING_FILE_BY_ID = 'admin/accounting/file/:id'

export const API_ADMIN_ALTERNATIVE_NAMES = 'admin/alternativeNames'

export const API_ADMIN_CONFIG_BY_TYPE = 'admin/config/:type'

/**
 * Reports
 */
export const API_REPORTS = '/reports/all'
export const API_REPORTS_PLACEHOLDERS = '/reports/placeholders'
export const API_REPORTS_PREVIEW = '/reports/preview'
export const API_REPORTS_DOWNLOAD = '/reports/download'
export const API_REPORTS_CSV_DOWNLOAD = '/reports/csvdownload'
export const API_REPORTS_BY_ID = '/reports/:id'

/**
 * Closing Costs
 */
export const API_CLOSING_COST_SETUPS = '/closingCostSetup'
export const API_GET_CLOSING_COST_SETUP = '/closingCostSetup/:id'
export const API_CLOSING_COST_SETUP_UPDATE = '/closingCostSetup/:id'
export const API_LOAN_GET_ELECTIVE_CLOSING_COSTS = '/loan/hud1/getElectiveClosingCosts'
export const API_LOAN_ADD_CLOSING_COSTS = '/loan/hud1/addClosingCosts'
export const API_LOAN_DELETE_CLOSING_COST = '/loan/hud1/deleteClosingCost/:id'

export const API_WAREHOUSE_LENDER = '/warehouseLender'
export const API_WAREHOUSE_LENDER_BY_ID = '/warehouseLender/:id'

export const API_ADMIN_CREDENTIALS = '/admin/config/credentials'

export const API_LOAN_APPLICATION_TEMPLATE = '/loanApplicationTemplate'

/**
 * Loan Payment
 */
export const API_LOAN_PAYMENT = 'loan/loanPayment'
export const API_LOAN_PAYMENT_BY_ID = 'loan/loanPayment/:id'
export const API_LOAN_PAYMENT_FILL = 'loan/loanPayment/getLoanPaymentFillData'

/**
 * Reminder
 */

export const API_REMINDER = '/reminder'
export const API_REMINDER_BY_ID = '/reminder/:id'
export const API_REMINDER_FIND_ALL = '/reminder/listAll'
export const API_REMINDER_LOG = '/reminder/log/:no/:key'
